import {
  CircularProgress,
  DialogContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Draft2 } from "../../../../models/drafts";
import { ProductPosition, SearchResult } from "../../../../models/product";
import AuthStore from "../../../../stores/AuthStore";
import DraftStore from "../../../../stores/DraftStore";
import ProductStore from "../../../../stores/ProductStore";
import DraftUndReservesSelector from "../../../drafts/DraftUndReservesSelector";
import styles from "./SearchPageTable.module.css";
import { SearchPageTableRow } from "./SearchPageTableRow/SearchPageTableRow";
const StyledTableCell = withStyles(() => ({
  head: {
    color: "#3A3D4B",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 500,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
    backgroundColor: "#d6d7d7",
    padding: "12px",
  },
}))(TableCell);

export const SearchPageTable = () => {
  const authStore = AuthStore.Instance;
  const store = ProductStore.Instance;
  const draftStore = DraftStore.Instance;
  const user = authStore.getCurrentUser();
  const [results, setResults] = useState<SearchResult>();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [draftPosition, setDraftPosition] = useState<
    ProductPosition | undefined
  >(undefined);
  const [draftAmount, setDraftAmount] = useState<number>(1);

  useEffect(() => {
    const subscription = store.getSearch().subscribe((value) => {
      setResults(value);
    });
    const loadingSubscription = store.getSearchLoading().subscribe((value) => {
      setLoading(value);
    });
    return () => {
      subscription.unsubscribe();
      loadingSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToDraftAndReservesClick = useCallback(
    (position: ProductPosition, amount: number) => {
      setDraftPosition(position);
      setDraftAmount(amount);
      setModalOpen(true);
    },
    []
  );

  const handleDraftSelect = useCallback(
    (draft: Draft2) => {
      if (draft && draftPosition) {
        draftStore.addToDraft(
          draftPosition.uuid,
          draft.draftuuid,
          draftAmount || 1
        );
      }
      setModalOpen(false);
    },
    [draftAmount, draftPosition, draftStore]
  );

  const tableContent = useMemo(() => {
    if (results?.firtsPage && results.firtsPage.length > 0) {
      return (
        <TableContainer>
          <Table aria-label='search products table'>
            <TableHead>
              <TableRow>
                <StyledTableCell width={35}>Цвет</StyledTableCell>
                <StyledTableCell>Наименование</StyledTableCell>
                <StyledTableCell>Цена</StyledTableCell>
                <StyledTableCell>Остаток</StyledTableCell>
                <StyledTableCell width={90} align='center'>
                  Дата транзита
                </StyledTableCell>
                <StyledTableCell align='center'>Количество</StyledTableCell>
                <StyledTableCell align='center' width={300}>
                  Действия
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results?.firtsPage.map((item) => (
                <SearchPageTableRow
                  key={item.id}
                  position={item}
                  onDraftAndReservesClick={handleToDraftAndReservesClick}
                  course_our={user.UserData?.course_our}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return <></>;
  }, [
    handleToDraftAndReservesClick,
    results?.firtsPage,
    user.UserData?.course_our,
  ]);

  if (loading) {
    return (
      <div className={styles.spinnerWrapper}>
        <CircularProgress />
      </div>
    );
  }

  if (results?.firtsPage && results.firtsPage.length === 0) {
    return (
      <div className={styles.empty}>
        <span>Ничего не найдено</span>
      </div>
    );
  }

  return (
    <>
      {tableContent}
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogContent>
          <DraftUndReservesSelector
            loading={false}
            onDraftSelect={(draft) => {
              handleDraftSelect(draft);
            }}
          />
        </DialogContent>
      </Modal>
    </>
  );
};
