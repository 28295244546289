import { useEffect, useState } from "react";
import { ProductPosition, SearchResult } from "../../models/product";
import AuthStore from "../../stores/AuthStore";
import ProductStore from "../../stores/ProductStore";
import styles from "./LCListing.module.css";
import LCProductLine from "./LCProductLine";

interface LCListingProps {
  onAddClick: (position: ProductPosition, amount: number) => void;
  draftWhGuid: string;
}

const LCListing = (props: LCListingProps) => {
  const { onAddClick, draftWhGuid } = props;
  const store = ProductStore.Instance;
  const authStore = AuthStore.Instance;
  const user = authStore.getCurrentUser();
  const [results, setResults] = useState<SearchResult>();

  useEffect(() => {
    const subscription = store.getSearch().subscribe((value) => {
      setResults(value);
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {results?.firtsPage && (
        <div className={styles.pane}>
          {results?.firtsPage?.map((item) => {
            const stock = item.whStocks.find(
              (whStock) => whStock.wh_guid === draftWhGuid
            )?.stock;
            return (
              <LCProductLine
                stock={stock || "0"}
                key={item.id}
                position={item}
                onAddClick={onAddClick}
                course_our={user.UserData?.course_our}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default LCListing;
